.PropertyControl {
  padding: 0 var(--margin);
  overflow: hidden auto;

  &.locked {
    background-color: var(--c_blue_transparent);

    header .title {
      color: var(--c_blue);
    }
  }

  header {
    padding: var(--margin);
    margin-left: calc(var(--margin) * -1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    flex-shrink: 0;
    width: 100%;
    //border-bottom: var(--border-width) solid var(--c_grey);
    background-color: var(--c_blue_transparent);

    .title {
      font-size: var(--f_size_head);
    }
  }

  .actions {
    padding: var(--margin) 0;
    display: flex;
    user-select: none;
    cursor: pointer;
    justify-content: flex-end;
    width: calc(100% + 2 * var(--margin));
    margin-left: calc(var(--margin) * -1);
    //border-top: var(--border-width) solid var(--c_grey);
    background-color: var(--c_blue_transparent);
  }

  .button_property {
    &:last-child {
      margin-right: var(--margin);
    }

    width: 20%;
    background-color: white;
    padding: var(--margin_half);
    border: var(--border-width) solid var(--c_grey-dark);
    border-radius: var(--border-radius);
    cursor: pointer;
    text-align: center;
    margin: 0 var(--margin_half);
  }

  &.table_administration_models_MediaStation {
    .has_2_inputsignals.form-row {
      float: left;
      width: 30%;

      .entry {
        align-items: center;
      }
      label {
        padding: var(--margin_half) 0;
      }
    }

    .name_second_input {
      width: calc(70% );
      float: left;
      clear: right;

      input {
        margin-top: 0 !important;
        margin-left: var(--margin_half);
        width: calc(100% - 1.5 * var(--margin)) !important;
      }

      label {
        display: none;
      }
    }
    .second_input_active {
      clear: both;
    }
  }
}