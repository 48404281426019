.ListControl {
  display: flex;

  @for $i from 2 through 5 {
    &.col-#{$i} .node {
      width: calc(100% / #{$i} - 2 * var(--margin));
    }
  }

  .node {
    cursor: pointer;
    user-select: none;
    flex-shrink: 0;
    //overflow: auto;
    height: calc(100vh - 2 * var(--margin) - var(--top-bar-height));
    padding: var(--margin);
    position: relative;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: var(--margin);
      height: calc(100% - 2 * var(--margin));
      border-right: var(--border-width) solid var(--c_grey-dark);
    }

    &:last-child:after {
      top: 0;
      height: 100%;
    }

    & .item.active {
    }

    & .item.selected {
      font-weight: bold;
    }

    .head_pane {
      margin-bottom: var(--margin);
      display: flex;
      justify-content: space-between;

      .title {
        display: flex;
        align-items: center;

        &, & * {
          font-size: var(--f_size_head);
        }

        select {
          height: 40px;
          border: var(--border-width) solid var(--c_grey-dark);
          border-radius: var(--border-radius);
          padding: 0 var(--margin_half);
          outline: none;
          width: 90%;

          option {
            font-weight: normal;
            font-size: 16px;
          }
        }
      }

      .add_entry {
        width: 40px;
        height: 40px;
        background-size: contain;
        flex-shrink: 0;
        background-image: var(--ip_list_add);

        &.disabled {
          opacity: .25;
          pointer-events: none;
        }
      }
    }

    .body_pane {
      overflow-y: auto;
      height: calc(100% - 2 * var(--margin));
      padding-bottom: var(--margin);
    }

    .item {
      margin: var(--margin_half) calc(var(--margin) * -1);
      padding: var(--margin_half) calc(2 * var(--margin)) var(--margin_half) var(--margin);

      &.active {
        border: var(--border-width) solid var(--c_blue);
        //border-top: var(--border-width) solid var(--c_blue);
        //border-bottom: var(--border-width) solid var(--c_blue);
        width: calc(100% - 2 * var(margin));
        margin: var(--margin_half) var(--margin-half);
        border-radius: var(--border-radius);
        color: var(--c_blue);
        background-repeat: no-repeat;
        background-position: calc(100% - var(--margin)) center;
        background-image: var(--ip_list_arrow);
      }

      &.active.selected {
        //border-top-color: var(--c_blue_transparent);
        //border-bottom-color: var(--c_blue_transparent);
        background-color: var(--c_blue_transparent);
      }
    }
  }
}