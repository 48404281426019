.ProgramPlanning {
  $width: 115px;

  ._head_column {
    width: var(--planer_col_width);
    height: var(--planer_col_width);
    border: var(--std_border);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    position: absolute;
    box-sizing: border-box;
    z-index: 1;
  }

  padding: 20px;

  display: flex;
  overflow: hidden;
  height: calc(100% - 40px);

  .confirm_delete {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    background-color: var(--c_decker);
    justify-content: center;
    align-items: center;
    z-index: 15;

    .message_box {
      padding: 40px;
      background-color: white;
      border-radius: var(--border-radius);

      .title, .info {
        margin-bottom: 30px;
      }

      .title {
        font-size: var(--f_size_head);
      }

      .info2 {
        margin-top: calc(var(--margin_half) * -1);
        margin-bottom: 30px;
      }

      .actions {
        justify-content: center;

        & > div {
          margin: 0 20px;
          padding: var(--margin_half);
          width: 100px;
          text-align: center;
          border-radius: var(--border-radius);
          border: var(--std_border);
          cursor: pointer;
        }
      }
    }
  }

  .Calendar {
    position: absolute;
    user-select: none;
    z-index: 2;
    height: calc(100% - 20px);

    .head_calendar {
      @extend ._head_column;

      .buttons {
        margin-top: 15px;
        display: flex;
        justify-content: space-evenly;

        & > div {
          width: 40px;
          height: 40px;
          border-radius: 35px;
          border: var(--std_border);
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          user-select: none;
        }

        & > .prev, & > .next {
          background-image: var(--ip_arrow);
          background-repeat: no-repeat;
          background-position: center 45%;
          transform: rotate(270deg);
        }

        & > .next {
          transform: rotate(90deg);
        }
      }

      .date {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;

        .edit_settings {
          display: flex;
          justify-content: center;
          text-align: center;
        }
      }
    }

    .scroll_days {
      height: inherit;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .days {
        &.pool_open {
          margin-bottom: calc(var(--height_pool) + 3 * var(--margin));
        }

        .day_blank {
          height: calc(var(--planer_col_width) - var(--border-width));
        }

        .day {
          background: white;
          border: var(--std_border);
          border-top: none;
          box-sizing: border-box;
          height: var(--icon-width);
          width: 115px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          &.today {
            background-color: var(--c_grey);
            position: relative;
          }

          &.active:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 5px;
            background-color: var(--c_blue);
            height: 100%;
          }

          &:last-child {
            border-radius: 0 0 var(--border-radius) var(--border-radius);
          }

          &.default_show {
            background-color: var(--c_grey);
          }

          &:last-child {
            //margin-bottom: 50px;
          }
        }
      }
    }

  }

  .spacer_left {
    width: var(--planer_col_width);
    flex-shrink: 0;
  }

  .ScheduleBox {
    height: calc(100% - 2 * var(--margin));
    width: calc(100% - var(--planer_col_width));
    flex-shrink: 0;
    flex-grow: 0;
    overflow: auto;
    display: flex;
    z-index: 1;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    .building_part {
      border: var(--std_border);
      border-radius: var(--border-radius);
      height: 100%;
      width: var(--icon-width);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 0;
      flex-shrink: 0;
      margin: 0 10px;
      position: sticky;
      top: 0;

      .head_schedule {
        height: calc(var(--planer_col_width) - 3px);
        border-radius: var(--border-radius);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-shrink: 0;
        z-index: 1;

        .toggle_title {
          .toggle {
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            transform: rotate(180deg);
            margin-top: calc(var(--margin_half) * 1.6);
            background-image: var(--ip_schedule_arrow);

            &.selected {
              margin-top: 0;
              margin-right: var(--margin);
              margin-left: var(--margin_half);
              transform: rotate(0);
            }
          }

          .title {
            white-space: nowrap;

            width: 0;
            margin-left: 4px;
            margin-top: 60px;

            &.selected {
              margin-top: 0;
              margin-left: var(--margin_half);

              span {
                font-size: var(--f_size_title);
              }
            }

            span {
              transform: rotate(90deg);
              transform-origin: 0 bottom;
              display: block;
              font-size: var(--f_size_head);
            }
          }
        }
      }

      &.selected {
        width: auto;
        height: fit-content;
        border-top: none;

        &.pool_open {
          margin-bottom: calc(var(--height_pool) + 3 * var(--margin));
        }

        .head_schedule {
          border-top: var(--std_border);
          border-radius: var(--border-radius);
          width: 100%;
          position: sticky;
          top: 0;
          background: white;

          .toggle_title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-grow: 1;

            .title {
              order: 0;
              width: unset;
              white-space: unset;

              span {
                transform: rotate(0);
              }
            }

            .toggle {
              order: 1;
            }
          }

          .media_stations {
            display: flex;
            justify-content: space-between;
            height: var(--icon-width);
            border-bottom: calc(1 * var(--border-width)) solid;

            .sortable_media_station {
              width: 100%;
              border-bottom: none;

              .title_ms {
                width: 100%;
                box-sizing: border-box;
                border-left: calc(var(--border-width) / 2) solid var(--c_grey-dark);
                border-right: calc(var(--border-width) / 2) solid var(--c_grey-dark);

                flex-grow: 1;
                display: flex;
                justify-content: center;
                position: relative;
                align-items: center;

                &.active:after {
                  content: '';
                  position: absolute;
                  bottom: 0;
                  height: 5px;
                  width: 100%;
                  background-color: var(--c_blue);
                  left: 0;
                }

                &:last-child {
                  border-right: none;
                }

                &:first-child {
                  border-left: none;
                }
              }
            }
          }
        }
      }

      .bottom_schedule {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .entry_ms {
          width: 100%;
          flex-grow: 1;
          box-sizing: border-box;
          border-right: var(--std_border);
          display: flex;
          flex-direction: column;

          &:last-child {
            border-right: none;
          }

          .day_entry {
            min-width: var(--planer_col_width);
            height: var(--icon-width);
            box-sizing: border-box;
            border-bottom: var(--std_border);
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &.today {
              background-color: var(--c_grey);
            }

            &.time_invalid {
              background-color: rgba(255, 172, 144, 0.55);
            }

            &.today.time_invalid {
              background-color: rgba(255, 172, 144, 0.55);
            }

            &.selected {
              background-color: var(--c_blue_light);
            }

            //&:first-child {
            //  border-top: var(--std_border);
            //}

            &:last-child {
              border-bottom: none;
              height: 47px;
            }

            .categories {
              width: var(--planer_col_width);
              height: calc(100% - 10px);
              padding-left: 5px;
              display: flex;
              overflow: hidden;
              align-items: center;
              background-size: 0 0;

              &.red:empty {
                flex-grow: 0;
                flex-shrink: 0;
                width: calc(var(--margin) * 1.2) !important;
                height: calc(var(--margin) * 1.2);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                background-image: var(--ip_schedule_warning);
              }

              .category {
                flex-grow: 0;
                flex-shrink: 0;
                margin-right: 7px;
                width: calc(25% - 7px);
                height: 90%;
                border-radius: var(--border-radius);
              }
            }

            &.invalid_time {
              background-color: darksalmon;
            }
          }

          &.error {
            .day_entry {
              background-color: darksalmon;
            }
          }
        }
      }
    }

    .InfoMediastation {
      position: absolute;
      top: 5px;
      right: 5px;
      border-radius: 10px;
      width: calc(var(--margin) * .75);
      height: calc(var(--margin) * .75);
      background: url("/css/icons/info_i.svg");
      background-size: contain;
    }
  }

  .ContentPoolHolder {
    user-select: none;
    position: fixed;
    width: calc(100vw - 4 * var(--margin));
    height: var(--height_pool);
    border: var(--std_border);
    border-radius: var(--border-radius);
    background-color: white;
    padding: var(--margin);
    bottom: var(--margin);
    z-index: 10;
    display: flex;
    flex-direction: column;

    .header {
      justify-content: space-between;
      align-items: center;
      margin-bottom: var(--margin_half);

      .title {
        font-size: var(--f_size_head);
        margin-right: var(--margin);
      }

      .left {
        align-items: center;

        .toggle_filter {
          .button {
            border: var(--std_border);
            border-radius: var(--border-radius);
            padding: var(--margin_slim) var(--margin_half);
            margin-left: var(--margin_half);

            &.selected {
              color: var(--c_blue);
              border-color: var(--c_blue);
            }
          }
        }
      }

      .right {
        .close_pool {
          height: var(--icon-width_smaller);
          width: var(--icon-width_smaller);
          background-size: contain;
          background-image: var(--ip_close);
        }
      }
    }

    .content {
      justify-content: space-between;
      overflow: hidden;
      flex-grow: 0;

      .left {
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        margin-right: 10px;

        &::-webkit-scrollbar {
          width: 5px;
        }

        .dragger {
          margin-bottom: var(--margin_half);
          margin-right: var(--margin_half);
        }

        .item {
          width: 120px;
          height: 120px;
          justify-content: flex-start;
          align-items: center;
          border-radius: var(--border-radius);
          border-width: var(--border-width);
          border-style: solid;
          padding: var(--margin_half);


          .symbol {
            width: 55%;
            height: 55%;
            background-size: cover;
            box-sizing: border-box;

            &.preview {
              border-radius: var(--border-radius);
              margin-bottom: var(--margin_half);
              border: none;
            }
          }

          .title {
            text-align: center;
            font-weight: bold;
            word-wrap: break-word;
            //padding: var(--margin_half);
            width: 100%;
          }
        }
      }

      .right {
        .button {
          width: calc(var(--height_pool) / 3.3);
          height: calc(var(--height_pool) / 3.3);
          align-items: center;
          justify-content: center;
          background-color: transparent;
          background-size: contain;
          border: none;
          margin: var(--margin_half) 0;

          background-repeat: no-repeat;
          background-position: center;

          &.add_content {
            background-image: var(--ip_pool_plus);
          }

          &.delete_content {
            background-image: var(--ip_pool_bin);
          }
        }
      }
    }
  }

  .DayProgram {
    position: fixed;
    width: calc(100vw - 4 * var(--margin));
    height: var(--height_pool);
    background-color: var(--c_blue_light);
    border: var(--std_border);
    border-radius: var(--border-radius);
    bottom: var(--margin);
    padding: var(--margin);
    z-index: 10;

    .AlternateOpenings {
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      position: fixed;
      background-color: rgba(0, 0, 0, 0.8);
      justify-content: center;
      align-items: center;
      font-weight: bold;
      white-space: pre-line;

      .message_box {
        padding: 40px;
        background-color: white;
      }
    }

    .header {
      justify-content: space-between;
      margin-bottom: var(--margin_half);

      .left {
        align-items: center;

        .title {
          margin-right: 100px;
          cursor: pointer;
          font-size: var(--f_size_head);
        }

        .opening {
          align-items: center;

          .watch, .warning_watch {
            width: var(--icon-width_smaller);
            height: var(--icon-width_smaller);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            margin-right: var(--margin_half);
            background-image: var(--ip_clock);
          }

          .warning_watch {
            margin-left: var(--margin_half);
            width: calc(var(--icon-width_smaller) / 1.5);
            height: calc(var(--icon-width_smaller) / 1.5);
            background-image: var(--ip_schedule_warning);
          }
        }
      }

      .right {
        .close_pool {
          height: var(--icon-width_smaller);
          width: var(--icon-width_smaller);
          background-size: contain;
          background-image: var(--ip_close);
        }
      }
    }

    .content {
      justify-content: space-between;
      width: 100%;

      .left {
        &::-webkit-scrollbar {
          height: 8px;
        }

        &::-webkit-scrollbar-track {
          background: white;
        }

        &::-webkit-scrollbar-thumb {
          background: var(--c_blue);
        }

        padding-bottom: 8px;

        height: inherit;
        overflow-x: auto;

        .timed_item {
          padding-right: var(--margin);
          border-right: var(--std_border);
          margin-right: 25px;

          .title {
          }

          .preview_holder {
            display: flex;

            .preview {
              margin: var(--margin_half);
              margin-left: 0;
              padding-left: calc(var(--height_pool) / 2 * (3 / 2));
              height: calc(var(--height_pool) / 2);
              background-size: cover;
              border-radius: var(--border-radius);
            }
          }

          &:last-child {
            border-right: none;
          }

          &.disabled {
            opacity: .3;
            pointer-events: none;
          }

          .options-1 > div.bo, .options-2 > div.bo {
            width: var(--icon-width_smaller);
            height: var(--icon-width_smaller);
            border-radius: 20px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            &.disabled {
              pointer-events: none;
              opacity: .3;
            }
          }

          .options-1 {
            margin-top: 10px;
            display: flex;
            flex-direction: column;

            & > div {
              margin-bottom: var(--margin_half);
            }

            .edit, .is_exclusive, .mute_audio {
              user-select: none;
              cursor: pointer;
              justify-content: center;
              align-items: center;
              margin-right: 5px;
            }

            .edit {
              background-image: var(--ip_schedule_edit);
            }

            .is_exclusive {
              background-image: var(--ip_schedule_exclusive);

              &.selected {
                background-image: var(--ip_schedule_exclusive_active);
              }
            }

            .mute_audio {
              background-image: var(--ip_schedule_not_muted);

              &.selected {
                background-image: var(--ip_schedule_muted);
              }
            }
          }

          .options-2 {
            margin-top: 10px;
            align-items: center;

            & > div {
              &.bo {
                width: calc(var(--icon-width_smaller) * .5);
                height: calc(var(--icon-width_smaller) * .5);
              }

              margin-right: var(--margin_half);
              height: 25px;
              flex-grow: 0;
              flex-shrink: 0;
              flex-wrap: nowrap;
              display: flex;
              align-items: center;
            }

            .valid_date_time.date {
              background-image: var(--ip_schedule_datum);

              &.selected {
                background-image: var(--ip_schedule_datum_active);
              }
            }

            .valid_date_time.time {
              background-image: var(--ip_schedule_time);

              &.selected {
                background-image: var(--ip_schedule_time_active);
              }
            }

            input {
              width: 50px;
              border: none;
              outline: none;
              background-color: var(--c_grey-light);
              padding: 5px;
              display: flex;
              text-align: center;
              margin: 0 3px;
              border-radius: var(--border-radius);

              &.duration {
                width: 35px;
                margin-left: 15px;
                text-align: right;
              }

              &.error {
                background-color: #ffcfca;
              }
            }
          }
        }
      }

      .right {
        padding-left: var(--margin_half);

        &.inactive {
          opacity: .3;
          pointer-events: none;
        }

        .button {
          width: calc(var(--height_pool) / 3.5);
          height: calc(var(--height_pool) / 3.5);
          //border-radius: var(--border-radius);
          border: none;
          background-color: transparent;
          align-items: center;
          justify-content: center;
          //background-color: var(--c_grey);
          margin: var(--margin_half) 0;

          background-repeat: no-repeat;
          background-position: center;

          &.add_content {
            background-image: var(--ip_pool_plus);
          }

          &.delete_content {
            background-image: var(--ip_pool_bin);
          }
        }
      }
    }
  }

  .PopUpContentAction, .PopUpDayElementAction {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .message {
      background-color: white;
      border-radius: var(--border-radius);
      padding: 40px 80px 50px;
      width: 40vw;

      .title {
        margin-bottom: 30px;
        font-size: var(--f_size_head);
      }

      .header {
        justify-content: center;
        display: flex;
        margin: var(--margin);
      }

      .option {
        padding: 5px;
        margin: 15px 0;
      }

      .spacer {
        height: var(--margin);
      }
    }
  }
}

.ContentPoolFilter {
  display: flex;
  align-items: center;
  margin-left: var(--margin);

  & > div {
    border: var(--c_background) 2px solid;
    color: var(--c_background);
    width: 40px;
    height: 40px;
    margin: var(--margin_half);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &.selected {
      border-color: var(--c_blue);
      color: var(--c_blue);
    }
  }
}

//Portal
.SettingsShows {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 10;
  justify-content: center;
  align-items: center;

  .settings {
    background-color: white;
    border-radius: var(--border-radius);
    max-height: calc(80% - var(--icon-width));
    width: calc(50% - var(--icon-width));
    padding: var(--icon-width);
    justify-content: space-between;

    .head {
      justify-content: space-between;
      margin-bottom: var(--margin);

      .title {
        font-size: var(--f_size_head);
      }
    }

    .show_list {
      flex-grow: 1;
      overflow-y: auto;

      .sortable_media_station {
        .element:before {
          content: '';
          width: 15px;
          height: 20px;
          background-color: var(--c_grey);
          margin-right: 10px;
          flex-shrink: 0;
          border-radius: var(--border-radius);
          cursor: n-resize;
        }
      }

      .element {
        justify-content: space-between;
        align-items: center;
        border-bottom: var(--std_border_light);
        padding: var(--margin_half) 0;

        & > * {
          &:last-child {
            margin-right: 0;
          }

          margin-right: 15px;
        }

        input[type="text"] {
          padding: 5px;
          width: 100%;
        }

        input[type="checkbox"],
        input[type="checkbox"]:focus,
        {
          border: none;
          outline-offset: 0;
          margin-right: 15px;
        }

        .default {
          white-space: nowrap;
        }

        .delete {
          width: var(--icon-width_smaller);
          height: var(--icon-width_smaller);
          flex-shrink: 0;
          background: url("/css/icons/trash.svg");
          background-size: contain;
        }
      }
    }

    .save {
      margin-top: var(--margin);
      justify-content: center;

      .simple_button {
        width: 20%;
      }
    }
  }

  .confirm_delete_show {
    border-radius: var(--border-radius);
    background-color: white;
    width: 25%;
    padding: var(--icon-width);

    .title {
      margin-bottom: var(--margin);
      font-size: var(--f_size_head);
    }

    .choices {
      margin-top: 25px;
      justify-content: center;

      .simple_button {
        width: 25%;
      }

      & > * {
        padding: 7px 15px;
        margin: 5px;
      }
    }
  }
}

.panel_show_mediainfo {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  justify-content: center;
  align-items: center;

  .mediainfo {
    border-radius: var(--border-radius);
    width: 80%;
    height: 80%;
    background-color: white;
    padding: calc(var(--margin) * 1.5);

    .head {
      justify-content: space-between;

      .title {
        font-size: var(--f_size_head);
      }

      .close {
        width: var(--icon-width);
        height: var(--icon-width);
        background: url("/css/icons/close.svg");
      }
    }

    .body {
      margin-top: 30px;
      width: 100%;
      flex-grow: 1;

      .left {
        width: 40%;

        .header_infos {
          margin-bottom: var(--margin_half);
        }

        .einbau {
          margin-bottom: var(--margin);
        }

        .infos {
          margin-bottom: var(--margin);
          white-space: pre-line;
        }

        div[class^="header_"] {
          font-weight: bold;
          margin-bottom: var(--margin_half);
        }

        .opening {
          margin-top: calc(var(--margin) * 2);
          width: 70%;

          .header_opening {
            margin-bottom: var(--margin_half);
          }

          & > div {
            margin-top: var(--margin_slim);
          }

          .time {
            div:first-child {
              width: 40%;
            }

            div:last-child div:nth-child(2) {
              padding: 0 10px;
            }
          }
        }
      }

      .right {
        width: 60%;
        display: flex;
        justify-content: space-between;

        .title {
          font-weight: bold;
        }

        .lage, .station {
          width: calc(50% - var(--margin_half));


          .img_lage, .img_station {
            margin-top: 20px;
            width: 100%;
            padding-top: 100%;
            border: var(--margin_half) solid var(--c_grey);
            box-sizing: border-box;

            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: var(--c_grey);
            border-radius: var(--border-radius);
          }
        }
      }
    }
  }
}

.helper {
  border-right: none;
  transform: rotate(45deg);
}

.drag_clone {
  .item {
    width: 120px;
    height: 120px;
    margin-left: -60px;
    margin-top: -60px;
    opacity: .5;
    justify-content: flex-start;
    align-items: center;

    .symbol {
      width: 55%;
      height: 55%;
      background-size: cover;
      box-sizing: border-box;

      &.preview {
        border: none;
        border-radius: var(--border-radius);
        margin-bottom: var(--margin_half);
      }
    }

    .title {
      font-weight: bold;
    }
  }
}