.SetVolume {
  .Regler {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    user-select: none;

    //& * {
    //  pointer-events: none;
    //}

    .speaker, .percent {
      width: 10%;
      display: flex;
      justify-content: center;
    }

    .speaker {
      width: var(--icon-width);
      height: var(--icon-width);
      background-image: var(--ip_speaker);

      &.silenced {
        background-image: var(--ip_no_speaker);
      }
    }

    .percent {
      pointer-events: none;
    }

    .track {
      pointer-events: none;
      position: relative;
      height: calc(6 * var(--margin));
      width: 80%;
      display: flex;
      align-items: center;

      & * {
        pointer-events: none;
      }

      &.silenced {
        opacity: .5;
        pointer-events: none;
      }

      .bg {
        width: 100%;
        height: var(--margin_half);
        background-color: var(--c_grey);
        border-radius: var(--margin);
      }

      .progress {
        background-color: var(--c_blue);
        height: var(--margin_half);
        position: absolute;
        border-radius: var(--margin);
      }

      .knob {
        position: absolute;
        height: var(--margin);
        width: var(--margin);
        border-radius: 15px;
        background-color: white;
        border: var(--border-width) var(--border-style) var(--c_grey-dark);
        left: -10px;
        z-index: 2;
      }
    }
  }

  .volumes {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;

    .volume {
      display: flex;
      align-items: center;
      width: calc(100% - var(--margin) * 10);
      border-bottom: var(--border-width) var(--border-style) var(--c_grey);
      padding: calc(2 * var(--margin)) calc(4 * var(--margin));

      .title, .spacer {
        width: 25%;
      }
    }
  }
}