@media (max-width: 500px) {
  .App, body {
    overflow-y: auto;
  }
  .App.is_logged_out {
    .Dashboard {
      flex-direction: column-reverse;

      .section_top {
        height: auto !important;
        min-height: calc(3 * var(--margin));
        flex-direction: column;

        .top_left {
          & > *:nth-child(n + 2) {
            display: none;
          }

          .homelink {
            height: var(--icon-width);
          }
        }

        .top_right {
          display: flex;
          flex-direction: column;
        }
      }

      .section_body {
        .intro_image {
          background-size: 95% !important;
          background-position: 100% center !important;
        }

        div[class^="section_"]:nth-child(n + 2) {
          display: none !important;
        }
      }

      .Login > div {
        display: flex;
        flex-direction: column;
        width: 100%;

        & > * {
          width: 80% !important;
          margin-top: 20px;
        }

        .login {
          margin-bottom: 20px;
          background-position: center;
        }

        .forgot_password {
          display: none;
        }
      }
    }
  }

  .App.is_logged_in {
    .section_top {
      .top_left {
        & > *:nth-child(n + 2) {
          display: none;
        }
      }

      .top_right {
        & > * {
          display: none;
        }

        & > .Login {
          display: flex;

          .options {
            width: calc(100vw - 2 * var(--margin));
            top: var(--top-bar-height);
            left: 0;
            right: unset;
            position: fixed;

            .change_pw {
              display: none;
            }
          }
        }
      }
    }

    .section_body {
      & > *:nth-child(n + 2) {
        display: none;
      }

      .section_control {
        .modules {
          flex-direction: row;
          flex-wrap: wrap;
          align-content: flex-start;
          justify-content: center;
        }
      }

      .loaded_module {
        .SetVolume {
          .volume {
            width: calc(100% - 2 * var(--margin));
            padding: var(--margin);
            display: flex;
            flex-direction: column;

            .title {
              width: 100%;
            }

            .Regler {
              & > * {
                flex-shrink: 0;
              }

              .track {
                margin: 0 calc(var(--margin) * 1.5);
                width: auto;
                flex-grow: 1;
              }
            }
          }
        }

        .SignalSource {
          .signal {
            width: calc(100% - 2 * var(--margin));
            padding: var(--margin);
            display: flex;
            flex-direction: column;

            .title {
              width: 100%;
              margin-bottom: var(--margin);
            }
          }
        }

        .RunInstantContent {
          .show {
            width: calc(100% - 2 * var(--margin));
            padding: var(--margin);
            display: flex;
            flex-direction: column;

            & > .title {
              width: 100%;
              margin-bottom: var(--margin);
            }

            .confirm {
              width: 100%;
              margin-top: var(--margin);

              .inner_confirm {
                & > * {
                  flex-shrink: 0;
                }
              }
            }
          }
        }

        .MachineWatch {
          flex-direction: column;

          &.station_selected {
            .lists {
              display: none;
            }
          }

          &.no_station_selected {
            .settings {
              display: none;
            }
          }

          .lists {
            height: 100%;

            .col_area {
              display: none;
            }

            .col_stations {
              width: calc(100vw - 2 * var(--margin));

              &:after {
                display: none;
              }
            }
          }

          .settings {
            width: 100%;
            height: 100%;

            .SettingStation {
              .title.headline {
                .back_media_station {
                  width: calc(var(--margin) * 1.75);
                  height: calc(var(--margin) * 1.75);
                  margin: calc(var(--margin) * -1) 0;
                  background-size: 60%;
                  background-position: center 45%;
                  background-repeat: no-repeat;
                  background-image: var(--ip_arrow);
                  transform: rotate(270deg);
                  border: var(--border-width) solid var(--c_grey-dark);
                  border-radius: 100%;
                  margin-right: var(--margin);
                  flex-shrink: 0;
                }

                .InfoMediastation {
                  display: none;
                }
              }
            }

            .change_times {
              display: none;
            }
          }
        }
      }
    }
  }
}