.export_holder {
  height: 100%;
  width: var(--icon-width);

}

.inner_export {
  cursor: pointer;
  display: none;
  height: 100%;
  width: 100%;
  background: var(--ip_export_machine) no-repeat center;
}