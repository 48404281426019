.ListPropertyControl {
  display: flex;
  width: 100%;
  height: 100%;

  .ListControl {
    flex-grow: 1;
  }

  .PropertyControl {
    width: calc(100% / 5 * 2);
    flex-shrink: 0;
  }

  .ConfirmDeleteEntry {
    position: fixed;
    z-index: 15;
    background-color: rgba(0, 0, 0, 0.9);
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;

    .container {
      border-radius: var(--border-radius);
      background-color: white;
      padding: var(--icon-width);
      width: 25%;

      .title {
        font-weight: bold;
        margin-bottom: 25px;
      }

      .buttons {
        margin-top: calc(var(--margin) * 2);
        justify-content: center;

        & > div {
          width: 30%;
        }
      }
    }
  }
}