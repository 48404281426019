.Login {
  .is_inactive {
    display: flex;
    color: white;
    align-items: center;

    & > * {
      height: var(--icon-width);
      margin: 0 calc(var(--margin) / 2);
    }

    .login {
      width: var(--icon-width);
      background-repeat: no-repeat;
      background-image: var(--ip_login);
    }

    .wrong_login {
      display: flex;
      align-items: center;
      padding-left: calc(var(--icon-width) + var(--margin_half));
      background-repeat: no-repeat;
      background-position: left center;
      margin-right: var(--margin);
      white-space: pre-line;
      color: white;
      background-image: var(--ip_warning_yellow);
    }

    input {
      padding-left: var(--margin);
      padding-right: var(--margin);
      border-radius: var(--border-radius);
      border: none;
      outline: none;
      width: calc(100vw / 5);
    }

    .forgot_password {
      display: flex;
      align-items: center;
      padding: 0 calc(var(--margin) * 1.5) 0 var(--margin);
      cursor: pointer;

      span {
        color: white;
        border-bottom: 1px dotted white;
        font-style: italic;
      }
    }

    .forget_send,
    .forget_close {
      width: var(--icon-width);
      height: var(--icon-width);
      cursor: pointer;
    }

    .forget_send {
      background-image: var(--ip_white_ok);
    }

    .forget_close {
      margin-right: var(--margin);
      background-image: var(--ip_close);
    }

    .forget_description {
      white-space: pre-wrap;
      text-align: left;
      display: flex;
      align-items: center;
      color: white;
    }
  }

  .is_active {
    margin: 0 var(--margin);
    height: 100%;
    width: var(--icon-width);
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    z-index: 10;
    background-image: var(--ip_person);

    &.activated {
      background-image: var(--ip_person_green);
    }

    .options {
      position: absolute;
      top: 100%;
      right: calc(var(--margin) * -1);
      background: var(--c_grey-dark);
      padding: var(--margin);
      width: calc(100vw / 5);

      .first, .last {
        white-space: pre;
        color: white;
      }

      .change_pw, .logout {
        border-radius: var(--border-radius);
        border: var(--border);
        padding: var(--margin_half);
        border-color: white;
        color: white;
        margin-bottom: var(--margin);
        text-align: center;
      }

      .last {
        margin-bottom: calc(2 * var(--margin));
      }

      border-radius: 0 0 var(--border-radius) var(--border-radius);

      & > *:last-child {
        margin-bottom: 0;
      }
    }

    .change_password {
      position: absolute;
      top: 100%;
      right: calc(var(--margin) * -1);
      width: 100vw;
      height: calc(100vh - 100%);
      background-color: var(--c_decker);
      display: flex;
      align-items: center;
      justify-content: center;

      .change_form {
        background-color: white;
        border-radius: var(--border-radius);
        width: 30%;
        padding: calc(2 * var(--margin));
        position: relative;

        .close {
          width: 50px;
          height: 50px;
          position: absolute;
          top: calc(var(--margin) * 1.5);
          right: calc(var(--margin) * 2);
          background-image: var(--ip_close);
        }

        .title {
          font-size: var(--f_size_head);
          margin-bottom: calc(var(--margin) * 2);
        }

        .description {
          margin-bottom: var(--margin_half);
        }

        .password {
          margin-bottom: var(--margin);
        }

        input {
          width: calc(100% - var(--margin));
        }

        .description.small {
          margin-top: calc(var(--margin_half) * -1);
          margin-bottom: calc(var(--margin) * 1);
          font-size: var(--f_size_small);
        }

        .button_white {
          padding: var(--margin_half) var(--margin);
          border-radius: var(--border-radius);
          border: var(--border);
          width: max-content;
          margin: 0 auto;
          cursor: pointer;
        }

        .check_error {
          margin-bottom: var(--margin);
          display: flex;
          align-items: center;

          .error_sign {
            width: 50px;
            height: 50px;
            background-repeat: no-repeat;
            background-size: contain;
            margin-right: var(--margin);
            margin-top: var(--margin_half);

            background-image: var(--ip_warning);
          }
        }
      }
    }
  }
}