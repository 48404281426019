:root {
    --ip_add: url("./add.svg");
    --ip_arrow: url("./arrow.svg");
    --ip_arrow_button: url("./arrow_button.svg");
    --ip_arrow_down: url("./arrow_down.svg");
    --ip_arrow_down_white: url("./arrow_down_white.svg");
    --ip_clock: url("./clock.svg");
    --ip_close: url("./close.svg");
    --ip_close_simple: url("./close_simple.svg");
    --ip_close_simple_grey: url("./close_simple_grey.svg");
    --ip_close_white: url("./close_white.svg");
    --ip_ContentPool: url("./ContentPool.svg");
    --ip_error_0: url("./error_0.svg");
    --ip_error_1: url("./error_1.svg");
    --ip_error_2: url("./error_2.svg");
    --ip_export: url("./export.svg");
    --ip_filter: url("./filter.svg");
    --ip_help: url("./help.svg");
    --ip_help_active: url("./help_active.svg");
    --ip_home: url("./home.svg");
    --ip_info_i: url("./info_i.svg");
    --ip_intro: url("./intro.svg");
    --ip_lens: url("./lens.svg");
    --ip_list_add: url("./list_add.svg");
    --ip_list_arrow: url("./list_arrow.svg");
    --ip_login: url("./login.svg");
    --ip_MachineAndRoom: url("./MachineAndRoom.svg");
    --ip_MachineWatch: url("./MachineWatch.svg");
    --ip_no_speaker: url("./no_speaker.svg");
    --ip_ok: url("./ok.svg");
    --ip_person: url("./person.svg");
    --ip_person_green: url("./person_green.svg");
    --ip_plus: url("./plus.svg");
    --ip_pool_bin: url("./pool_bin.svg");
    --ip_pool_plus: url("./pool_plus.svg");
    --ip_power_off: url("./power_off.svg");
    --ip_power_on: url("./power_on.svg");
    --ip_power_undefined: url("./power_undefined.svg");
    --ip_ProgramPlanning: url("./ProgramPlanning.svg");
    --ip_Restaurants: url("./Restaurants.svg");
    --ip_RunInstantContent: url("./RunInstantContent.svg");
    --ip_schedule_arrow: url("./schedule_arrow.svg");
    --ip_schedule_datum: url("./schedule_datum.svg");
    --ip_schedule_datum_active: url("./schedule_datum_active.svg");
    --ip_schedule_edit: url("./schedule_edit.svg");
    --ip_schedule_exclusive: url("./schedule_exclusive.svg");
    --ip_schedule_exclusive_active: url("./schedule_exclusive_active.svg");
    --ip_schedule_muted: url("./schedule_muted.svg");
    --ip_schedule_not_muted: url("./schedule_not_muted.svg");
    --ip_schedule_time: url("./schedule_time.svg");
    --ip_schedule_time_active: url("./schedule_time_active.svg");
    --ip_schedule_warning: url("./schedule_warning.svg");
    --ip_SetInstantContent: url("./SetInstantContent.svg");
    --ip_SetVolume: url("./SetVolume.svg");
    --ip_SignalSource: url("./SignalSource.svg");
    --ip_speaker: url("./speaker.svg");
    --ip_TenantAndRoom: url("./TenantAndRoom.svg");
    --ip_trash: url("./trash.svg");
    --ip_User: url("./User.svg");
    --ip_warning: url("./warning.svg");
    --ip_warning_yellow: url("./warning_yellow.svg");
    --ip_WeekMenus: url("./WeekMenus.svg");
    --ip_white_ok: url("./white_ok.svg");
    --ip_zoom: url("./zoom.svg");

    --ip_stat_error: url("./stat_error.svg");
    --ip_stat_warning: url("./stat_warning.svg");
    --ip_camera: url("./camera.svg");
    --ip_export_machine: url("./export_machines.svg");

    --ip_icon_media: url("./icon_media.svg");
    --ip_icon_video: url("./icon_video.svg");

    --ip_move_image: url("./move_image.svg");
}