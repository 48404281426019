.MediumInfo {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 10;
  font-weight: bold;

  .inner_medium_info {
    background-color: white;
    border-radius: var(--border-radius);
    width: 80%;
    padding: var(--icon-width);
    position: relative;

    .title.big {
      font-size: 20px;
      margin-bottom: 30px;
    }

    .closer {
      position: absolute;
      right: 20px;
      top: 20px;
      height: var(--icon-width_smaller);
      width: var(--icon-width_smaller);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: var(--ip_close);
    }

    .file-actions {
      justify-content: space-between;
      text-align: center;
      align-items: center;
      padding-bottom: 30px;

      .file-left {
        width: 35%;

        .file-button {
          height: 40px;
          justify-content: center;
          border: var(--std_border);
          border-radius: var(--border-radius);
        }
      }

      .file-middle {
        width: 5%;
      }

      .file-right {
        width: 45%;
        height: 60px;
        justify-content: center;

        border: 5px dashed;
        box-sizing: border-box;
        position: relative;

        &:after {
          content: 'Datei hier hinziehen';
          position: absolute;
          top: -4px;
          left: -4px;
          background-color: lightgrey;
          width: calc(100% + 8px);
          height: calc(100% + 8px);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .file-list {
      max-height: 40vh;
      overflow: auto;

      .file-item {
        justify-content: space-between;
        border-top: 1px solid;
        padding: var(--margin) 0 0 0;

        .bo {
          height: 25px;
        }

        .title {
          margin-bottom: 10px;
        }


        .group-filenname {
          flex-grow: 1;

          .filename {
            font-weight: normal;
            padding: var(--margin_half);
            padding-right: calc(var(--margin) * 2);
            border: var(--std_border);
            border-radius: var(--border-radius);
            position: relative;

            .closer {
              content: '';
              background: url('/css/icons/close_simple_grey.svg') no-repeat center;
              background-size: 50%;
              position: absolute;
              width: calc(1.5 * var(--margin));
              height: 100%;
              top: 0;
              right: 0;
            }
          }
        }

        .group-validity {
          padding: 0 20px;

          .validity {
            & > div {
              margin: 0 5px;
            }

            .minus {
              padding: var(--margin_half);
            }

            [name="valid_from"], [name="valid_to"] {
              width: 150px;
              border: none;
              background: var(--c_grey);
              padding: var(--margin_half);
              border-radius: var(--border-radius);
              outline: none;
            }
          }
        }

        .group-copyright {
          flex-grow: 1;

          input {
            width: calc(100% - var(--margin));
            border: none;
            background: var(--c_grey);
            padding: var(--margin_half);
            border-radius: var(--border-radius);
            outline: none;
          }
        }

      }
    }
  }
}
