.Dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;

  @keyframes hov {
    0% {
      opacity: 0
    }
    50% {
      opacity: 1
    }
    100% {
      opacity: 0
    }
  }

  .loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    font-weight: bold;
    font-size: calc(var(--f_size_head) * 3);
    color: var(--c_grey-dark);
    opacity: .8;
    animation: infinite 2s hov;
  }

  .loaded_module {
    & > * {
      height: 100%;
    }
  }

  .section_top {
    display: flex;
    justify-content: space-between;

    height: var(--top-bar-height);
    background-color: var(--c_grey-dark);

    flex-shrink: 0;

    .top_left, .top_right {
      display: flex;

      .f_head, .homelink, .update, .Help, .Login {
        font-size: var(--f_size_head);
        align-items: center;
        user-select: none;
      }

      .homelink {
        width: var(--icon-width);
        margin: 0 var(--margin);
        cursor: pointer;

        &.with_error {
          background: no-repeat center/100% var(--ip_error_2);
        }

        &.with_warning {
          background: no-repeat center/100% var(--ip_error_1);
        }

        &.without_error {
          background: no-repeat center/auto 45% var(--ip_home);
        }
      }

      .update {
        background-repeat: no-repeat;
        background-size: auto 45%;
        background-position: center;
        width: var(--icon-width);
        margin: 0 var(--margin) 0 0;
        background-image: var(--ip_export);
        cursor: pointer;
        transform: rotate(0deg);
      }

      .update_turn {
        transform: rotate(360deg);
      }

      .Help, .Login {
        cursor: pointer;
      }
    }

    .Search {
      align-items: center;
      cursor: pointer;
    }
  }

  .section_body {
    display: flex;
    flex-grow: 1;
    position: relative;
    overflow: hidden;

    .intro_image {
      height: 60vh;
      width: 100vw;
      background-repeat: no-repeat;
      background-position: 25% 55%;
      background-size: auto 60%;
      background-image: var(--ip_intro);
    }

    .loaded_module {
      position: absolute;
      background-color: white;
      height: 100%;
      width: 100%;

      box-sizing: border-box;
    }

    div[class^="section_"] {
      overflow: hidden auto;
      margin: var(--margin) 0;
      flex-grow: 1;
      border-right: var(--border);
      border-right-color: var(--c_grey);
      width: 100%;
      display: flex;
      flex-direction: column;

      &:last-child {
        border-right: none;
      }

      header {
        text-align: center;
        font-size: var(--f_size_head);
        margin-top: var(--margin);
      }

      .modules {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        .dash_image {
          padding: 0 20px;

          &.SetVolume {
            background-image: var(--ip_SetVolume);
          }

          &.SignalSource {
            background-image: var(--ip_SignalSource);
          }

          &.MachineWatch {
            background-image: var(--ip_MachineWatch);
          }

          &.RunInstantContent {
            background-image: var(--ip_RunInstantContent);
          }

          &.ContentPool {
            background-image: var(--ip_ContentPool);
          }

          &.ProgramPlanning {
            background-image: var(--ip_ProgramPlanning);
          }

          &.TenantAndRoom {
            background-image: var(--ip_TenantAndRoom);
          }

          &.SetInstantContent {
            background-image: var(--ip_SetInstantContent);
          }

          &.User {
            background-image: var(--ip_User);
          }

          &.MachineAndRoom {
            background-image: var(--ip_MachineAndRoom);
          }

          &.Restaurants {
            background-image: var(--ip_Restaurants);
          }
        }

        & > div {
          cursor: pointer;
        }

        div {
          width: var(--button-module-size);
          height: var(--button-module-size);
          margin-top: calc(2 * var(--margin));
          background-repeat: no-repeat;
          background-size: 60% 40%;
          background-position: center 25%;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          text-align: center;

          .dash_title {
            display: flex;
            align-items: center;
            height: 45%;
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .section_events {
    .module_bankett {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .event_entry {
        &:first-child {
          margin-top: calc(var(--margin) * 2);
        }

        display: flex;
        flex-direction: column;
        width: calc(100% - 2 * var(--margin));
        margin-top: var(--margin);

        .profi {
          &:empty {
            display: none;
          }

          &.room {
            margin-bottom: 5px;
            font-size: var(--f_size_small);
            font-weight: bold;
          }

          &.title_event {
            font-weight: bold;
            margin-top: calc(var(--margin_half) * .5);
            margin-bottom: 5px;
          }

          &.descr_event {
            margin-top: 5px;
          }
        }
      }
    }
  }
}