.RunInstantContent {
  .shows_instant {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;

    .show {
      display: flex;
      align-items: center;
      width: calc(100% - var(--margin) * 10);
      border-bottom: var(--border-width) var(--border-style) var(--c_grey);
      padding: calc(2 * var(--margin)) calc(4 * var(--margin));
      justify-content: space-between;

      .state.toggle_button {
        margin: 0 var(--margin);
        border: var(--border-width) var(--border-style) transparent;
        background-color: var(--c_grey);
        width: calc(2 * var(--icon-width_smaller));
        height: var(--icon-width_smaller);
        border-radius: var(--icon-width_smaller);

        display: flex;
        align-items: center;

        &.active {
          justify-content: flex-end;
          background-color: var(--c_blue);
        }

        .knob {
          border: var(--border-width) var(--border-style) var(--c_grey-dark);
          height: calc(var(--icon-width_smaller) - var(--border-width) * 4);
          width: calc(var(--icon-width_smaller) - var(--border-width) * 4);
          margin: 3px;
          border-radius: var(--icon-width_smaller);
          background-color: white;
        }
      }

      .title, .confirm {
        width: 40%;
      }

      .inner_confirm {
        .title {
          margin-right: var(--margin);
          display: flex;
          align-items: center;
        }

        .yes, .cancel {
          width: var(--icon-width_smaller);
          height: var(--icon-width_smaller);
          margin: 0 var(--margin);
          background-size: contain;
        }

        .yes {
          background-image: var(--ip_ok);
        }

        .cancel {
          background-image: var(--ip_close);
        }

        display: none;
      }
    }
  }
}