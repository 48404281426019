.SearchProgramPlanning {
  & * {
    color: var(--c_background);
  }

  .collapsed {
    width: 330px;

    .results {
      width: inherit;
      overflow: hidden;
      height: var(--icon-width_smaller);
      border-radius: var(--border-radius);
      background: var(--ip_lens) no-repeat 5%, var(--ip_arrow_down) no-repeat 95%, white;
      display: flex;
      align-items: center;

      .names_selected_filters {
        display: flex;
        align-items: center;
        color: var(--c_grey-dark);
        user-select: none;
        pointer-events: none;
        width: calc(100% - 2 * var(--icon-width_smaller) - var(--margin_half));
        height: 100%;
        margin-left: calc(var(--icon-width_smaller) + var(--margin_half));
        white-space: nowrap;
        overflow: hidden;
        position: relative;

        &:after {
          content: '';
          height: 100%;
          background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
          position: absolute;
          top: 0;
          right: 0;
          width: var(--icon-width_smaller);
        }
      }
    }

    .item {
      &:after {
        content: ', ';
        white-space: pre;
      }

      &:last-child:after {
        content: none;
      }
    }
  }

  .unfolded {
    position: relative;
    width: 330px;
    height: var(--icon-width_smaller);

    .input_search {
      position: relative;
      height: calc(var(--top-bar-height) - var(--icon-width_smaller) / 2);
      line-height: 0;

      input {
        outline: none;
        border-radius: var(--border-radius);
        border: none;
        padding: 0 calc(var(--margin) * 2 + var(--margin_half));
        height: var(--icon-width_smaller);
        flex-shrink: 0;
        color: var(--c_grey-dark);
        background: var(--ip_lens) no-repeat 5%, var(--ip_arrow) no-repeat 95%, white;
      }

      .spacer {
        height: calc((var(--top-bar-height) - var(--icon-width_smaller)) / 2);
        flex-shrink: 0;
      }

      .arrow {
        position: absolute;
        right: 0;
        height: var(--icon-width_smaller);
        width: var(--icon-width_smaller);
      }

      .suggestions {
        position: absolute;
        z-index: 12;
        width: 100%;
        top: calc(var(--icon-width_smaller) - var(--border-radius));
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
        background-color: var(--c_grey-light);
        display: flex;
        flex-direction: column;
        height: calc(40vh - calc(var(--top-bar-height)) + 2 * var(--margin));
        overflow: hidden auto;

        &:empty {
          display: none;
        }

        .suggestion {
          margin-left: calc(var(--margin) * 2 + var(--margin_half));
          width: 78%;
          overflow-x: hidden;
          white-space: pre;
          cursor: pointer;
          height: 30px;
          display: flex;
          align-items: center;
          color: var(--c_grey-dark);
          flex-shrink: 0;
        }
      }
    }

    section.reset_filters {
      & > div {
        background-color: white;
        border-radius: var(--border-radius);
        padding: var(--margin_half) var(--margin);
        width: max-content;
        color: var(--c_grey-dark);
      }
    }

    section {
      margin-top: var(--margin);
      border-bottom: var(--border-width) var(--border-style) var(--c_grey);
      padding-bottom: var(--margin);

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      &.reset_filters {
        margin-top: 0;

        & > div {
          border: var(--border-width) solid white;
          background-color: unset;
          color: white;
          width: calc(100% - 2 * var(--margin));
          text-align: center;
        }
      }

      &:last-child {
        margin-bottom: var(--margin);
      }

      .filter_sign, .arrow {
        width: var(--f_size_title);
        height: var(--f_size_title);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }

      .filter_sign {
        margin-right: var(--margin_half);
        background-image: var(--ip_filter);
      }

      .arrow {
        background-image: var(--ip_arrow_down_white);

        &.not_collapsed {
          transform: rotate(180deg);
        }
      }
    }

    section .title .right .filter_sign.invisible {
      opacity: .3;
    }

    section.period {
      &.inactive {
        pointer-events: none;
        opacity: .3;
      }

      //zeitraum
      .years {
        justify-content: space-between;
        width: 100%;
        margin-bottom: var(--margin_half);

        .year {
          border: 1px solid;
          border-radius: 5px;
          padding: 1px 3px;
          cursor: pointer;

          &.active {
            color: var(--c_blue);
            border-color: var(--c_blue);
          }
        }

        .prev, .next {
          position: absolute;
          border: 1px solid;
          border-radius: 40px;
        }

        .prev {
          left: -20px
        }

        .next {
          right: -20px
        }
      }

      .months {
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;

        &.inactive {
          opacity: .3;
          pointer-events: none;
        }

        .month {
          cursor: pointer;
          width: calc(90% / 3);
          border: 1px solid;
          border-radius: 5px;
          margin: 5px 0;
          text-align: center;

          &.active {
            color: var(--c_blue);
            border-color: var(--c_blue);
          }
        }
      }
    }

    .holder_search {
      position: absolute;
      top: 0;
      z-index: 10;
      background-color: var(--c_grey-dark);
      padding: 0 var(--margin);
      border-bottom-left-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
      min-height: 40vh;

      .head {
        justify-content: space-between;
      }

      .item_list {
        display: flex;
        flex-wrap: wrap;
        padding-top: var(--margin_half);

        &:empty {
          padding-top: 0;
        }

        .item {
          &.status.active {
            background-color: white;
            color: var(--c_grey-dark);
          }

          border: var(--border-width) var(--border-style) white;
          padding: var(--margin_slim) var(--margin_half);
          display: flex;
          align-items: center;

          border-radius: 5px;
          margin: calc(var(--margin_slim) * 2);
          margin-left: 0;
          cursor: pointer;
          user-select: none;

          .delete_tag {
            height: var(--f_size_small);
            width: var(--f_size_small);
            margin-left: var(--margin_half);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-image: var(--ip_close_simple);
          }
        }
      }
    }
  }
}