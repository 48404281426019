.SignalSource {
  .signals {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;

    .signal {
      display: flex;
      align-items: center;
      width: calc(100% - var(--margin) * 10);
      border-bottom: var(--border-width) var(--border-style) var(--c_grey);
      padding: calc(2 * var(--margin)) calc(4 * var(--margin));

      .title {
        width: 25%;
      }

      .spacer {
        width: 10%;
      }

      .selector {
        display: flex;
        align-items: center;

        .activated {
          color: var(--c_blue);
        }

        .toggle_button {
          margin: 0 var(--margin);
          border: var(--border-width) var(--border-style) transparent;
          background-color: var(--c_grey);
          width: calc(2 * var(--icon-width_smaller));
          height: var(--icon-width_smaller);
          border-radius: var(--icon-width_smaller);

          display: flex;
          align-items: center;

          &.active {
            justify-content: flex-end;
            background-color: var(--c_blue);
          }

          .knob {
            border: var(--border-width) var(--border-style) var(--c_grey-dark);
            height: calc(var(--icon-width_smaller) - var(--border-width) * 4);
            width: calc(var(--icon-width_smaller) - var(--border-width) * 4);
            margin: 3px;
            border-radius: var(--icon-width_smaller);
            background-color: white;
          }
        }
      }
    }
  }
}