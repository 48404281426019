.hide_scrollbar {
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: none;
    border-radius: 50px;
  }

  ::-webkit-scrollbar-track {
    border: none;
    border-radius: 50px;
  }
}