.ContentPool {
  height: 100%;

  .left.items {
    width: 100%;
    align-items: flex-start;
    align-content: flex-start;

    .content_pool_items {
      flex-wrap: wrap;
      height: 100%;
      overflow-y: auto;
      align-items: flex-start;
      align-content: flex-start;
    }

    .item {
      width: 120px;
      height: 120px;
      margin: 20px;
      align-items: center;

      .image {
        width: 75px;
        height: 75px;
        flex-shrink: 0;
        background-size: contain;
        margin-bottom: var(--margin_half);
        border-radius: var(--border-radius);
        background-position: center;
        background-repeat: no-repeat;
        background-color: white;
        position: relative;

        &.has_video:after {
          content: '';
          position: absolute;
          top: 10%;
          right: 10%;
          background: center / contain var(--ip_icon_video);
          background-repeat: no-repeat;
          height: 25%;
          width: 25%;

        }
      }

      .title {
        text-align: center;
      }
    }
  }

  .right {
    flex-shrink: 0;
    justify-content: center;
    height: 100%;

    .add, .delete {
      width: 120px;
      height: 120px;
      align-items: center;
      justify-content: center;
      user-select: none;
      cursor: pointer;
    }

    .button {
      width: calc(var(--height_pool) / 3);
      height: calc(var(--height_pool) / 3);
      border-radius: var(--border-radius);
      align-items: center;
      justify-content: center;
      border: none;
      //background-color: var(--c_grey);
      margin: var(--margin_half) var(--margin);

      background-repeat: no-repeat;
      background-position: center;

    }

    .add {
      margin-bottom: 20px;
      background-image: var(--ip_pool_plus);
    }

    .delete {
      background-image: var(--ip_pool_bin);
    }
  }

  .confirm_delete {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .message {
      background-color: white;
      border-radius: var(--border-radius);
      padding: calc(var(--margin) * 2);
      width: 35vw;

      .title {
        margin-bottom: 30px;
        font-size: var(--f_size_head);
      }

      .info2 {
        margin-top: var(--margin_half);
      }

      .actions {
        margin-top: var(--margin_half);
        padding: 5px;
        justify-content: center;

        .cancel, .delete {
          border: var(--std_border);
          margin: var(--margin_half) var(--margin_half) 0 var(--margin_half);
          padding: var(--margin_half);
          border-radius: var(--border-radius);
          width: 100px;
          text-align: center;
        }
      }
    }
  }
}

.ContentPoolFilterPool {
  display: flex;
  align-items: center;
  margin-left: var(--margin);

  & > div {
    border: var(--c_background) 2px solid;
    color: var(--c_background);
    width: 40px;
    height: 40px;
    margin: var(--margin_half);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &.selected {
      border-color: var(--c_blue);
      color: var(--c_blue);
    }
  }
}