.Form {
  overflow: hidden auto;
  padding-bottom: var(--margin);

  .error_message {
    font-weight: bold;
    color: red;
  }

  [data-error="true"] {
    background-color: #ffccd2;
    border-color: red;
    outline: none;
  }

  &  label {
    font-weight: bold;
  }

  [data-blank="false"] > .entry {
    font-weight: bold;

    & > {
      label:after {
        content: ' *';
      }
    }
  }

  .form-row {
    padding: var(--margin) 0;
    border-bottom: var(--border-width) solid var(--c_grey);

    &.hide_row {
      visibility: hidden;
      height: 0;
      padding: 0;
    }

    .entry[datatype="checkbox"] {
      display: flex;
      flex-flow: row-reverse;
      justify-content: flex-end;

      label {
        margin-left: var(--margin_half);
      }
    }

    label {
      display: block;
    }

    input[type="time"], input[type="number"], input[type="text"], textarea[type="text"] {
      border: none;
      background: var(--c_grey);
      padding: var(--margin_half);
      border-radius: var(--border-radius);
      width: calc(100% - var(--margin));
      margin-top: var(--margin_half);
    }

    textarea[type="text"] {
      height: 100px;
    }

    &.locked {
      input[type="time"], input[type="number"], input[type="text"], textarea[type="text"] {
        background: none;
        padding: 0;
        width: 100%;
      }

      select {
        margin-top: var(--margin_half);
        background: none;
        opacity: 1;
        color: black;
        border: none;
        appearance: none;
      }
    }

    .entry[datatype="file"] {
      .form_image {
        margin-top: var(--margin_half);
        display: block;
        flex-shrink: 0;
        position: relative;
        width: 50%;
        padding-top: calc(50% * 2 / 3);
        user-select: none;
        background-color: var(--c_grey-dark);
        border-radius: var(--border-radius);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        .manual_select, .manual_delete {
          position: absolute;
          top: var(--margin_half);
          right: var(--margin_half);
          width: 15%;
          padding-top: 15%;
          background-size: contain;
          background-repeat: no-repeat;
          z-index: 2;

          &.plus {
            background-image: var(--ip_plus);
          }

          &.zoom {
            background-image: var(--ip_zoom);
          }

          &.manual_delete {
            top: var(--margin);
            padding-top: 30%;
            background-position-y: bottom;
            z-index: 1;
            background-image: var(--ip_trash);
          }
        }
      }
    }
  }
}

.zoom_holder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 100;

  .zomm_image {
    width: 100% !important;
    height: 100% !important;
    background-color: transparent !important;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      border-radius: 100%;
      top: var(--margin);
      right: var(--margin);
      height: var(--icon-width);
      width: var(--icon-width);
      background: var(--ip_close_white);
      background-color: var(--c_grey-dark);
    }
  }
}


.MachineAndRoom {
  .table_administration_models_BuildingPart {
    .Form {
      .form-row:nth-child(-1n + 14) {
        border-bottom: none;
      }

      .form-row:nth-child(-2n + 15) {
        label {
          width: 140px;
        }
      }

      &.locked {
        .form-row:nth-child(-2n + 15) {
          input {
            margin-right: calc(var(--margin) * -1);
          }
        }
      }

      .form-row:nth-child(-2n + 16) {
        label {
          width: 35px;
        }
      }

      .form-row:nth-child(-2n + 15), .form-row:nth-child(-2n + 16) {
        input {
          width: auto !important;
          margin: 0 var(--margin_half);
        }
      }

      .time_from_0, .time_from_1, .time_from_2, .time_from_3, .time_from_4, .time_from_5, .time_from_6 {
        float: left;
      }

      .time_from_0, .time_from_1, .time_from_2, .time_from_3, .time_from_4, .time_from_5, .time_from_6,
      .time_to_0, .time_to_1, .time_to_2, .time_to_3, .time_to_4, .time_to_5, .time_to_6 {
        padding-top: 0;

        .entry {
          display: flex;
          align-items: center;
        }

        label {
          white-space: nowrap;
        }

        input {
          margin-top: 0;
        }
      }
    }
  }

  //plugins

  .special_openings {
    border-top: var(--border-width) solid var(--c_grey);
    padding-top: var(--margin);
    margin-top: var(--margin);

    .button {
      margin-top: var(--margin);
      width: 50%;
      background-color: white;
      padding: var(--margin_half);
      border: var(--border-width) solid var(--c_grey-dark);
      border-radius: var(--border-radius);
      cursor: pointer;
      text-align: center;
    }
  }

  .locked .button {
    pointer-events: none;
  }
}

.User {
  .table_administration_models_User {
    .test-sms {
      border-top: var(--border-width) solid var(--c_grey);
      padding-top: var(--margin);
      margin-top: var(--margin);


    }
  }
}

