.MachineWatch {
  display: flex;

  .lists {
    flex-grow: 1;
    display: flex;
    user-select: none;

    & > div {
      width: 50%;
      padding: var(--margin);
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: var(--margin);
        height: calc(100% - 2 * var(--margin));
        border-right: var(--border-width) solid var(--c_grey-dark);
      }

      &:last-child:after {
        top: 0;
        height: 100%;
      }

      .title {
        font-size: var(--f_size_head);
        padding-bottom: var(--margin);
      }

      .item_building, .stations > .station {
        margin: var(--margin_half) calc(var(--margin) * -1);
        padding: var(--margin_half) calc(2 * var(--margin)) var(--margin_half) var(--margin);
        cursor: pointer;

        &:last-child {
          margin-bottom: calc(var(--margin) * 2);
        }
      }
    }

    .list_building {
      overflow-y: auto;
      height: 100%;


    }

    .list_building, .stations {
      .item_building.selected, .station.selected {
        background-color: var(--c_blue_transparent);
        border: var(--border-width) solid var(--c_blue);
        width: calc(100% - var(margin) * 2);
        margin: var(--margin_half) var(--margin-half);
        border-radius: var(--border-radius);
        color: var(--c_blue);
        background-repeat: no-repeat;
        background-position: calc(100% - var(--margin)) 50%;
        background-image: var(--ip_list_arrow);
        font-weight: bold;

        &.no_bg {
          background-color: transparent;
        }

        .name {
          color: var(--c_blue);
        }
      }
    }

    .stations {
      overflow-y: auto;
      height: 100%;

      .station {
        display: flex;
        align-items: center;

        .state {
          width: 25px;
          height: 25px;
          border-radius: 25px;
          background-color: red;
          margin-right: calc(2 * var(--margin));
          flex-shrink: 0;

          &.active {
            background-color: #8CD000;
          }

          &.inactive {
            background-color: grey;
          }
        }
      }
    }

    .allpower {
      float: right;

      a {
        text-decoration: none;
      }
    }
  }

  .settings {
    width: calc(100% / 5 * 2);
    flex-shrink: 0;
    background-color: var(--c_blue_light);

    .SettingStation {
      padding: var(--margin);
      height: calc(100% - 2 * var(--margin));

      .title, .headline {
        display: flex;
        justify-content: left;
        align-items: center;
        padding: var(--margin) !important;
        margin-top: calc(var(--margin) * -1) !important;
        margin-bottom: calc(var(--margin) * -.5) !important;
        background-color: var(--c_blue_transparent);

        & > div:first-child {
          font-size: var(--f_size_head);
          color: var(--c_blue);
        }

        & > div:last-child {
          flex-grow: 1;
          display: flex;
          justify-content: flex-end;
        }

        .power {
          width: calc(var(--margin) * 2);
          flex-shrink: 0;

          .power_button {
            width: inherit;
            height: calc(var(--margin) * 2);
            margin: calc(var(--margin) * -1) 0;
            background-size: contain;
            background-repeat: no-repeat;

            &.state_on {
              background-image: var(--ip_power_on);
            }

            &.state_off {
              background-image: var(--ip_power_off);
            }

            &.state_undefined {
              background-image: var(--ip_power_undefined);
            }
          }
        }
      }

      .props_station {
        height: 100%;
        display: flex;
        flex-direction: column;

        .change_times {
          border: var(--std_border);
          border-radius: var(--border-radius);
          width: 50%;
          margin-top: var(--margin_half);
          padding: var(--margin_half);
          text-align: center;
          cursor: pointer;
        }

        & > div {
          &:first-child {
            .station_name {
              //width: 80%;
            }
          }

          margin: var(--margin_half) calc(var(--margin) * -1);
          padding: var(--margin_half) calc(2 * var(--margin)) var(--margin_half) var(--margin);

          &.status {
            margin-bottom: 0;
          }

          &.show_logs {
            overflow-y: auto;
            margin-top: 0 !important;

            .log_entry {
              .level {
                margin-top: calc(var(--margin_half) * .5);
              }

              margin-bottom: var(--margin_half);
            }
          }
        }
      }
    }
  }

  .InfoMediastation {
    border-radius: 10px;
    width: calc(var(--margin) * 1.25);
    height: calc(var(--margin) * 1.25);
    background: var(--ip_info_i);
    flex-shrink: 0;
    background-size: contain;
    margin: 0 var(--margin_half);
  }

  .message_power {
    position: absolute;
    top: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.9);
    width: calc(100% - 2 * var(--margin));
    height: calc(100% - 2 * var(--margin));
    padding: var(--margin);

    .button_holder {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .question {
        margin-top: -200px;
        color: white;
        text-align: center;
      }

      & > div {
        margin-bottom: var(--margin);
        width: 250px;
      }
    }
  }
}