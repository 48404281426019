@keyframes ani-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animation_rotate {
  animation: ani-rotate;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

.ContentEditor {
  &.inactive {
    .template_view {
      pointer-events: none;
      opacity: .4;
    }

    .template_settings {
      .settings_1 {
        pointer-events: none;
        opacity: .4;
      }

      .settings_2 {
        .is_valid, .status, .tags_holder {
          pointer-events: none;
          opacity: .4;
        }
      }
    }
  }

  background-color: white;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  & > .head {
    justify-content: space-between;
    align-items: center;
    height: var(--top-bar-height);
    background-color: var(--c_grey-dark);
    flex-shrink: 0;
    padding: 0 var(--margin);

    & * {
      color: white;
      font-size: var(--f_size_head);
    }

    .closer, .Help {
      width: var(--icon-width);
      height: var(--icon-width);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .closer {
      background-image: var(--ip_close_white);
    }


    .Help {
      margin-right: var(--margin);
    }
  }

  .templates {
    height: 100%;
    flex-wrap: wrap;
    overflow-y: auto;
    padding: 25px;

    .template {
      border-right: var(--std_border);
      border-right-color: var(--c_grey-light);
      height: 250px;
      width: 220px;
      padding: 15px;

      .title {
        font-weight: bold;
        margin-bottom: 15px;
        width: inherit;
        height: 40px;
        text-align: center;
      }

      .image {
        width: inherit;
        height: 180px;
        background-size: cover;
        border-radius: var(--border-radius);
        background-position: center;
        border: var(--std_border_light);
      }
    }
  }

  .template {
    height: 100%;

    .template_view {
      width: calc(100% / 5 * 3);
      padding: var(--margin);
      border-right: var(--std_border_light);
      box-sizing: border-box;

      .template_top_bar {
        justify-content: space-between;
        user-select: none;
        height: 100px;
        margin-bottom: -50px;

        .title {
          cursor: pointer;
          display: flex;
          align-items: center;

          .toggle_button {
            margin: 0 var(--margin);
            border: var(--border-width) transparent;
            background-color: var(--c_grey);
            width: calc(2 * var(--icon-width_smaller));
            height: var(--icon-width_smaller);
            border-radius: var(--icon-width_smaller);

            display: flex;
            align-items: center;

            &.active {
              justify-content: flex-end;
              background-color: var(--c_blue);
            }

            .knob {
              border: var(--std_border);
              height: calc(var(--icon-width_smaller) - var(--border-width) * 4);
              width: calc(var(--icon-width_smaller) - var(--border-width) * 4);
              margin: 3px;
              border-radius: var(--icon-width_smaller);
              background-color: white;
            }
          }


          &.active {
            background-color: green;
          }
        }

        .button {
          width: var(--icon-width);
          height: var(--icon-width);
          border-radius: var(--icon-width);
          align-items: center;
          justify-content: center;
          border: var(--std_border);
          box-sizing: border-box;
          margin-right: var(--margin);
          padding: 0;

          &.trash_button {
            border-color: transparent;
            margin-left: var(--margin);
          }

          &.make_content_pic {
            box-sizing: border-box;
            background: no-repeat center var(--ip_camera);
          }

          &.move_image {
            box-sizing: border-box;
            background: no-repeat center var(--ip_move_image);
            background-size: contain;

            &[data-templatefilled="true"] {
              background-color: var(--c_blue_lighter);
            }
          }

          &.active {
            border-color: var(--c_blue);
          }

          .image {
            width: 70%;
            height: 70%;
            border-radius: 100%;
            background-size: cover;
            pointer-events: none;

            &.trash {
              width: 100%;
              height: 100%;
              background-image: var(--ip_trash);
            }
          }
        }

        div[data-templatefilled="true"] {
          background-color: var(--c_blue_lighter);
        }
      }

      .TemplateHolder {
        margin: var(--icon-width);
        flex-grow: 1;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;

        & > div {
          border: var(--std_border_light);
          max-height: 100%;
        }

        &.preview > div {
          border: var(--border-width) var(--border-style) white;
        }
      }

      .template_controls {
        justify-content: flex-end;
        align-items: center;

        .bo {
          width: var(--icon-width);
          height: var(--icon-width);
          border-radius: 100%;

          &.plus {
            background-image: var(--ip_plus);
          }

          &.trash {
            background-image: var(--ip_trash);
          }

          &.prev {
            background-image: var(--ip_arrow_button);
          }

          &.next {
            background-image: var(--ip_arrow_button);
            transform: rotate(180deg);
          }
        }

        div {
          margin: 0 var(--margin_half);
        }

        .counter {
          white-space: pre;
          flex-shrink: 0;
          font-weight: bold;
        }
      }
    }

    .template_settings {
      flex-grow: 0;
      flex-shrink: 0;
      width: calc(100% / 5 * 2);
      height: calc(100% - var(--margin) * 2);
      padding: var(--margin);

      display: flex;

      & > div > .title {
        text-align: center;
        font-size: var(--f_size_head);
        margin-bottom: var(--margin);
        padding-bottom: var(--margin);
      }

      & > div {
        padding: 0 20px;
        flex-grow: 0;
        width: 50%;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      .background-color, .way_to, .video_to_end, .campaign, .holder_category, .is_valid, .status, .countdown {
        border-bottom: var(--border-width) var(--border-style) var(--c_grey);
        padding-bottom: var(--margin);
        margin-bottom: var(--margin);

        &.countdown::after {
          display: none;
        }
      }

      .campaign {
        label {
          width: 100%;
        }
      }

      input {
        &.text_color, &.name_template {
          width: calc(100% - 1.5 * var(--margin))
        }
      }

      .tags_holder {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin-bottom: calc(var(--icon-width) * 1.25);
      }

      .save_holder {
        height: var(--icon-width);
        position: absolute;
        bottom: 0;
        width: calc(100% - 1 * var(--margin));
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .save_template {
          border: var(--std_border);
          border-radius: var(--border-radius);
          padding: var(--margin_half);
          width: 35%;
          text-align: center;
        }
      }

      .validity {
        display: flex;
      }

      .settings_1 {
        border-right: var(--border-width) var(--border-style) var(--c_grey);

        [name="way_to_select"].disabled {
          opacity: .5;
          pointer-events: none;
          user-select: none;
        }
      }

      .settings_2 {
        height: calc(100vh - 2 * var(--margin) - var(--top-bar-height));
        display: flex;
        flex-direction: column;
        overflow: hidden auto;
        position: relative;

        .tags_holder {
          overflow: hidden auto;
          flex-shrink: 1;
        }
      }

      .settings_1 > div, .settings_2 > div {
        & > label {
          clear: right;
          display: inline-block;
          margin-bottom: var(--margin_half);
        }

        & > select {
          display: block;
          width: calc(100% - var(--margin_half));
        }

        & > input[type="checkbox"] {
          margin-right: var(--margin_half);
          margin-left: 0;
        }

        & > input[type="date"], & > input[type="datetime-local"] {
          width: calc(100% - var(--margin) * 1.5);

          &[name="valid_from"] {
            margin-bottom: var(--margin_half);
          }

          &[name="valid_to"] {
            margin-top: var(--margin_half);
          }
        }

        & > input, & > select, & .new_tag {
          border: none;
          background: var(--c_grey);
          padding: var(--margin_half);
          border-radius: var(--border-radius);
          outline: none;
        }

        & .Tags {
          overflow: hidden;
          display: flex;
          flex-direction: column;

          .taglist {
            overflow-y: auto;
          }

          .tagEnter {
            position: relative;
            margin-bottom: var(--margin_half);

            .new_tag {
              width: calc(100% - var(--margin) * 3);
              padding-right: calc(var(--margin) * 2);
            }

            .add {
              background-repeat: no-repeat;
              background-position: center;
              position: absolute;
              content: 'qwe';
              height: 100%;
              width: var(--icon-width_smaller);
              top: 0;
              right: calc(var(--margin_half) * 1);
              background-image: var(--ip_add);
            }
          }
        }

        & .Tags .taglist {
          .tag {
            border: var(--std_border);
            position: relative;
            padding: var(--margin_slim) var(--margin_half);
            padding-right: calc(var(--margin) * 2);
            display: inline-flex;
            border-radius: var(--border-radius);
            overflow: hidden;
            margin: var(--margin_half) var(--margin_half) 0 0;

            .remove {
              position: absolute;
              background-image: var(--ip_close_simple_grey);
              width: calc(var(--margin) * 1.5);
              height: 100%;
              right: 0;
              top: 0;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }
      }
    }
  }

  .hide_description::after {
    display: none;
  }

  .preview [data-part-name]::after {
    display: none;
  }

  [contenteditable] {
    white-space: pre-wrap;
  }

  [data-part-name]::after {
    width: fit-content;
    height: fit-content;
    font-size: 12px;
    position: absolute;
    pointer-events: none;
    user-select: none;
    font-weight: bold;
    z-index: 1;
    text-align: center;
    white-space: pre;
    background-color: rgba(255, 255, 255, .75);
    border-radius: 5px;
    padding: 5px;
    margin-left: -5px;
    margin-top: -5px;
    color: black !important;
  }

  .tools_bottom {
    & > .container_text_tools {
      bottom: 0 !important;
    }
  }

  .tools_top {
    top: 0;

    & > .container_text_tools {
      bottom: 0;
    }
  }

  .confirm_change {
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;

    .is_saving {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: italic;
      font-weight: bold;
      font-size: calc(var(--f_size_head) * 3);
      color: white;
      opacity: .8;
      animation: infinite 2s hov;
    }
  }

  .PdfSelector {
    position: fixed;
    top: 0;
    z-index: 10;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--c_decker);
    display: flex;
    align-items: center;
    justify-content: center;

    .panel {
      background-color: var(--c_background);
      border-radius: var(--border-radius);
      padding: var(--margin);
      width: 30%;
      padding-top: 40%;
      padding-bottom: var(--margin_half);
      position: relative;

      .closer {
        position: absolute;
        border-radius: 100%;
        background-image: var(--ip_close);
        background-size: contain;
        height: var(--icon-width_smaller);
        width: var(--icon-width_smaller);
        top: var(--margin_half);
        right: var(--margin_half);
        z-index: 1;
      }

      #pdf-canvas {
        position: absolute;
        top: var(--margin);
        width: calc(100% - 2 * var(--margin));
        height: calc(100% - 4.5 * var(--margin));
      }

      .controls {
        height: var(--icon-width);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .next, .prev {
          height: var(--icon-width_smaller);
          width: var(--icon-width_smaller);
          border-radius: 100%;
          background-image: var(--ip_arrow_button);
          background-size: contain;

          &.next {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.container_text_tools {
  position: fixed;
  left: 0;
  width: 0;
  height: 0;
  z-index: 10;

  .text_tools {
    display: flex;
    flex-direction: column;
    background-color: #f8f8f8;
    padding: 20px;
    width: fit-content;
    position: absolute;
    border: var(--std_border);
    border-radius: var(--border-radius);

    & > .title {
      margin-bottom: var(--margin_slim);
    }

    div[class^="font_"] {
      margin-top: 15px;
    }

    .font_color, .font_size {
      align-items: center;

      label {
        width: 120px;
      }

      div {
        width: 150px;
      }
    }


    select, input {
      width: 100%;
      outline: none;
      border: none;
      outline: 0;
      padding: var(--margin_slim) var(--margin_half);
      background-color: var(--c_grey);
      border-radius: var(--border-radius);
    }

    select {
      //width: 150px;
    }
  }
}

.SpecialEditor {
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  & > .head {
    justify-content: space-between;
    align-items: center;
    height: var(--top-bar-height);
    background-color: var(--c_grey-dark);
    flex-shrink: 0;
    padding: 0 var(--margin);

    & * {
      color: white;
      font-size: var(--f_size_head);
    }

    .closer, .Help {
      width: var(--icon-width);
      height: var(--icon-width);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .closer {
      background-image: var(--ip_close_white);
    }

    .Help {
      margin-right: var(--margin);
    }
  }

  .sp_form {
    height: calc(100% - var(--top-bar-height));
    display: flex;
    justify-content: center;
    align-items: center;

    .upload_form {
      background-color: white;
      border: var(--std_border);
      border-radius: var(--border-radius);
      width: 70%;
      padding: calc(2 * var(--margin));
      font-weight: bold;

      &.loading {
        background-color: transparent;
        color: white;
        font-size: 40px;
        text-align: center;
        border: none;
      }

      .file-button, input[type="text"], .save_change {
        height: 40px;
        justify-content: center;
        border: var(--std_border);
        border-radius: var(--border-radius);
        align-items: center;
        cursor: pointer;
      }

      .save_change {
        margin-top: calc(2 * var(--margin));
        width: 30%;
        display: flex;
      }

      .title {
        margin-top: calc(var(--margin) * 2);
      }

      .title_head {
        text-align: center;
      }

      input[type="text"] {
        width: 50%;
        margin-top: var(--margin);
        cursor: text;
        padding: 0 var(--margin);
      }

      .file-actions {
        margin: var(--margin) 0;
        justify-content: space-between;
        align-items: baseline;

        .file-left {
          width: 30%;
        }

        .file-right {
          width: 30%;
          height: 60px;
          justify-content: center;
          border: 5px dashed;
          box-sizing: border-box;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            top: -4px;
            left: -4px;
            background-color: lightgrey;
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &.pre:after {
            content: 'Vorschaubild hier hinziehen';
          }

          &.app:after {
            content: 'Applikation hier hinziehen';
          }
        }

        .image_ref, .app_ref {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 25%;
          text-align: right;

          .delete {
            height: calc(2 * var(--margin));
            width: calc(2 * var(--margin));
            background: center / contain no-repeat var(--ip_close);
            cursor: pointer;
          }
        }
      }
    }
  }
}