.SpecialOpening {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
  z-index: 10;

  .message {
    background-color: white;
    width: 25%;
    height: 75%;
    overflow-y: hidden;
    justify-content: space-between;
    padding: var(--icon-width);
    position: relative;

    &.singleday {
      height: unset;
    }

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    & > .title, & > .part, & > .warning {
      margin-bottom: 15px;
    }

    .warning {
      color: red;
      white-space: pre-line;
      margin-bottom: 30px;

    }

    .SpecialCalendar {
      user-select: none;
      flex-grow: 1;
      flex-shrink: 1;
      overflow: hidden;

      .head_calendar {
        .buttons {
          margin-top: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;

          .schalter {
            width: 35px;
            height: 35px;
            border-radius: 35px;
            box-sizing: border-box;
            align-items: center;
            justify-content: center;
            background-size: contain;
            background-image: url("/css/icons/arrow_button.svg");
            background-repeat: no-repeat;
            background-position: center;

            &.next {
              transform: rotate(180deg);
            }
          }
        }
      }

      .scroll_days {
        height: 100%;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }

        .days {
          //&.pool_open {
          //  margin-bottom: $height-pool;
          //}

          //.day_blank {
          //  height: $width;
          //}

          .day {
            background: white;
            border-bottom: 3px solid black;
            box-sizing: border-box;
            height: var(--icon-width);
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: bold;
            position: relative;

            &.hide {
              display: none;
            }

            &.today {
              background-color: var(--c_blue_light);
              position: relative;
            }

            &:last-child {
              border-bottom: none;
            }

            &.single {
              border-top: 3px solid black;
              border-bottom: 3px solid black;
            }

            .title {
              width: 60px;
            }

            .time {
              .clock.off {
                color: red;
              }

              &.special, &.focused {
                input {
                  opacity: 1;
                }
              }

              input {
                width: 40px;
                border: none;
                outline: none;
                background-color: var(--c_grey);
                border-radius: var(--border-radius);
                font-weight: bold;
                padding: 5px;
                display: flex;
                text-align: center;
                margin: 0 3px;
                opacity: .5;

                &.error {
                  background-color: #955f54;
                }
              }
            }

          }
        }
      }
    }
  }
}

.SpecialOpening {
  .message {
    border-radius: var(--border-radius);
    padding: calc(var(--margin) * 1.5);

    & * {
      font-weight: normal;
    }

    .title {
      white-space: pre-line;
      font-size: var(--f_size_head);
    }

    .part:after {
      content: 'Achtung! Eine Änderung hat Auswirkungen auf alle Medienstationen in diesem Gebäudebereich.';
      display: block;
      margin-top: var(--margin);
      margin-bottom: var(--margin_half);
      color: red;
    }

    .close {
      font-size: 0;
      width: var(--icon-width_smaller);
      height: var(--icon-width_smaller);
      background: url('/css/icons/close.svg') no-repeat;
      background-size: contain;
    }

    .days {
      border-bottom: var(--std_border_light) !important;
    }

    .day.single {
      border-top: var(--std_border_light) !important;
      border-bottom: none !important;
      padding: 0 var(--margin);

      .time {
        align-items: center;

        .clock {
          background: url('/css/icons/clock.svg') no-repeat;
          background-size: contain;
          width: calc(var(--icon-width) / 2);
          height: calc(var(--icon-width) / 2);
          margin-right: var(--margin_slim);
          font-size: 0;
        }

        input {
          font-size: 16px;
        }
      }

      .error {
        font-size: 0;
      }
    }
  }
}