.Help {
  & * {
    color: white;
  }

  background-image: var(--ip_help);

  &.activated {
    background-image: var(--ip_help_active);
  }

  margin: 0 0 0 var(--margin);
  height: 100%;
  width: var(--icon-width);
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 10;

  .contact {
    display: none;
  }

  &.activated .contact {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: var(--top-bar-height);
    z-index: 11;
    padding: var(--margin);
    width: calc(100vw / 5 - 2 * var(--margin));

    background-color: var(--c_grey-dark);
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);

    & > div {
      margin: var(--margin_slim) 0;
    }

    .button_hilfe {
      margin-top: calc(var(--margin) * 2);
      padding: var(--margin_half);
      border: var(--std_border_light);
      border-radius: var(--border-radius);
      text-align: center;
    }
  }

  .InlineHelp {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100000;
    background-color: var(--c_grey-dark);

    iframe {
      border: none;
      position: absolute;
      top: var(--top-bar-height);
      left: 0;
      width: 100%;
      height: calc(100% - var(--top-bar-height));
      background-color: white;
    }

    //.close_help {
    //  background: var(--ip_close_white) no-repeat center;
    //  position: absolute;
    //  top: 0;
    //  right: calc(var(--top-bar-height) - var(--margin));
    //  height: var(--top-bar-height);
    //  width: var(--top-bar-height);
    //  z-index: 1;
    //}

    .close_help {
      background: no-repeat center/auto 45% var(--ip_home);
      cursor: pointer;
      //background: var(--ip_close_white) no-repeat center;
      position: absolute;
      top: 0;
      left: 0;
      height: var(--top-bar-height);
      width: var(--top-bar-height);
      z-index: 1;
      display: flex;
      align-items: center;

      .f_head {
        margin-left: var(--top-bar-height);
      }
    }
  }
}

