//all variables
@font-face {
  font-family: regular;
  src: url("css/fonts/Sparkasse_Rg.ttf");
}

:root {
  //colors
  --c_grey-dark: #333333;
  --c_grey: #D9D9D9;
  --c_grey-light: #EfEFEF;
  --c_background: white;
  --c_decker: rgba(0, 0, 0, 0.8);

  --c_blue: #00ACD3;
  --c_blue_lighter: rgba(0, 172, 211, 0.25);
  --c_blue_light: #e5f7fb;
  --c_blue_transparent: rgba(0, 172, 211, 0.1);

  --c_signal: orange;

  --f_size_head: 22px;
  --f_size_title: 18px;
  --f_size_small: 14px;

  --top-bar-height: 90px;

  --border: solid black 2px;

  --margin: 20px;
  --margin_half: 10px;
  --margin_slim: 3px;

  --border-width: 2px;
  --border-color: var(--c_grey-dark);
  --border-style: solid;
  --border-radius: 6px;

  --std_border: var(--border-width) var(--border-style) var(--border-color);
  --std_border_light: var(--border-width) var(--border-style) var(--c_grey);

  --button-module-size: 120px;

  --icon-width: 50px;
  --icon-width_smaller: 40px;

  --height_pool: 30vh;
  --planer_col_width: 115px;
}

// general classes
.white_button {
  border-radius: var(--border-radius);
  background-color: white;
  padding: var(--margin_half) 0;
  display: flex;
  justify-content: center;
  margin: var(--margin) 0;
}

.simple_button {
  margin: 0 20px;
  padding: var(--margin_half);
  text-align: center;
  border-radius: var(--border-radius);
  border: var(--std_border);
  cursor: pointer;
}

.simple_input {
  border: none;
  background: var(--c_grey);
  padding: var(--margin_half);
  border-radius: var(--border-radius);
  outline: none;
}

.grey_input {
  outline: none;
  border: none;
  background-color: var(--c_grey-light);
  padding: var(--margin_half);
  border-radius: var(--border-radius);
}

.f_white {
  color: white;
}

body {
  background: var(--background);
  overflow: hidden;

  & * {
    font-family: regular, sans-serif;
    font-size: 18px;
    color: var(--c_grey-dark)
  }
}

.fr {
  display: flex;
}

.fc {
  display: flex;
  flex-direction: column;
}

.drag_clone {
  position: absolute;
  z-index: 10000;
}

.PrintContent {
  display: none;
}