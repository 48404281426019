.section_status {
  .modules > div {
    margin-top: var(--margin) !important;
    width: 100% !important;
    display: flex !important;
    height: auto !important;
    align-items: flex-start !important;
  }


  .modules > div:first-child {
    //height: var(--button-module-size);
    margin-top: calc(2 * var(--margin)) !important;
  }

  .error {
    .symbol {
      background-image: var(--ip_stat_error);
      height: calc(var(--margin) * 2) !important;
      width: calc(var(--margin) * 2) !important;
      background-size: 100% 100% !important;
      margin: 0 var(--margin) 0 var(--margin) !important;
    }
  }

  .warning {
    .symbol {
      background-image: var(--ip_schedule_warning);
    }
  }

  .symbol, .message {
    user-select: none;
    width: calc(var(--margin) * 4) !important;

    margin-top: 0 !important;
    height: auto;
  }

  .message {
    flex-grow: 1;
    height: auto !important;
    margin-right: var(--margin_half);
    text-align: left !important;
    justify-content: flex-start !important;
  }
}